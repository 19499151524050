import { asError } from '@app/utils/asError'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { ApiActionBuilderDescriptor } from './builderDescriptor'
import { ApiActionBundle, ApiActionDescriptor, ApiActionPromise } from './types'
import { createApiAction } from './utils'

type ApiActionBuilderInit<R extends any, M extends any> = Omit<ApiActionDescriptor<ApiActionBundle<any, any, any, R, M>>, 'types'>

type ApiActionFullFill<R, M> = M extends undefined
  ? R extends undefined
    ? (payload?: R, meta?: M) => ThunkAction<void>
    : (payload: R, meta?: M) => ThunkAction<void>
  : (payload: R, meta: M) => ThunkAction<void>
type ApiActionReject<M> = M extends undefined ? (payload: Error, meta?: M) => ThunkAction<void> : (payload: Error, meta: M) => ThunkAction<void>
type ApiActionPending<M> = M extends undefined ? (meta?: M) => ThunkAction<void> : (meta: M) => ThunkAction<void>

type ApiActionBuilderAction<
  R extends ApiActionBuilderDescriptor<string, any, any>,
  Init extends (...args: any) => ThunkAction<ApiActionPromise<R['shapes']['fulfilled']['payload']>>,
> = Init & {
  descriptor: R
  getMeta: (...args: Parameters<Init>) => R['shapes']['fulfilled']['meta']
  pending: ApiActionPending<R['shapes']['fulfilled']['meta']>
  fulfill: ApiActionFullFill<R['shapes']['fulfilled']['payload'], R['shapes']['fulfilled']['meta']>
  reject: ApiActionReject<R['shapes']['fulfilled']['meta']>
}

export class ApiActionBuilder<D extends ApiActionBuilderDescriptor<string, any, any>, Init extends (...args: any) => ApiActionBuilderInit<any, any>> {
  private descriptor: D
  private init?: Init

  constructor(descriptor: D) {
    this.descriptor = descriptor
  }

  setInit<NewInit extends (...args: any) => ApiActionBuilderInit<D['shapes']['fulfilled']['payload'], D['shapes']['fulfilled']['meta']>>(init: NewInit) {
    this.init = init as any
    return this as any as ApiActionBuilder<D, NewInit>
  }

  build() {
    const label = this.descriptor.label
    if (!label) throw new Error('Must provide label')

    const init = this.init
    if (!init) throw new Error('Must provide init')

    const types = [`${label}_PENDING`, `${label}_FULFILLED`, `${label}_REJECTED`] satisfies [string, string, string]

    const action = (...args: any) => {
      const data = init(...args)
      return createApiAction({
        ...data,
        types,
      })
    }

    action.actions = types
    action.getMeta = (...args: any) => init(...args).meta
    action.descriptor = this.descriptor
    action.fulfill = (payload: D['shapes']['fulfilled']['payload'], meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_FULFILLED`, payload, meta })
      })
    action.reject = (payload: Error, meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_REJECTED`, error: true, payload, meta })
      })
    action.pending = (meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_PENDING`, meta })
      })

    return action as any as ApiActionBuilderAction<D, (...args: Parameters<Init>) => ThunkAction<ApiActionPromise<D['shapes']['fulfilled']['payload']>>>
  }
}

export const createDispatcher = <D extends ApiActionBuilderDescriptor<string, any, any>>(
  descriptor: D
): {
  pending: ApiActionPending<D['shapes']['fulfilled']['meta']>
  fulfill: ApiActionFullFill<D['shapes']['fulfilled']['payload'], D['shapes']['fulfilled']['meta']>
  reject: ApiActionReject<D['shapes']['fulfilled']['meta']>
} => {
  const label = descriptor.label
  return {
    fulfill: (payload: D['shapes']['fulfilled']['payload'], meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_FULFILLED`, payload, meta })
      }),
    reject: (payload: Error, meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_REJECTED`, error: true, payload, meta })
      }),
    pending: (meta: D['shapes']['fulfilled']['meta']) =>
      createThunk(dispatch => {
        dispatch({ type: `${label}_PENDING`, meta })
      }),
  } as any
}

export function createApiActionStub<
  A extends ApiActionBuilderAction<ApiActionBuilderDescriptor<string, any, any>, (...args: any) => ThunkAction<ApiActionPromise<any>>>,
>(action: A, mock: (...args: Parameters<A>) => ReturnType<A>) {
  const m = mock as any
  m.descriptor = action.descriptor
  return ((...args: Parameters<A>): ThunkAction<ApiActionPromise<any>> =>
    async (dispatch, _getState) => {
      const meta = action.getMeta(...args)
      try {
        dispatch(action.pending(meta))
        const res = await dispatch((m as A)(...args))
        if (res?.error) {
          dispatch(action.reject(res.payload, meta))
        } else if (res) {
          dispatch(action.fulfill(res.payload, meta))
        }
        return res
      } catch (e) {
        dispatch(action.reject(asError(e), meta))
        throw e
      }
    }) as any as A
}
